import { Link  } from 'react-router-dom';
import {useEffect, useState} from "react";
import {reset} from "bootstrap/js/src/util/scrollbar";

const MenuDash = ()=>{

    const [users, setUsers] = useState([]);
    const [users1, setUsers1] = useState([]);
    const [len, setLen] = useState();
    const [len1, setLen1] = useState();
    const [testdata, setTestdata] = useState([])
    const [testdata1, setTestdata1] = useState([])


    async function getDate1(event){
        // event.preventDefault()
        const response= await fetch("https://api-cwipedia.herokuapp.com/api/admin/view_students");
        const data= await response.json();
        // console.log(data)
        setTestdata1(data);
        setUsers1(data);
        setLen1(data.length);
    }



    async function getDate(event){
        // event.preventDefault()
        const response= await fetch("https://api-cwipedia.herokuapp.com/api/admin/get_timer");
        const data= await response.json();
        // console.log(data)
        setTestdata(data);
        setUsers(data);
        setLen(data.length);
    }

    let d = new Date();
    let time_s = d.toLocaleTimeString();
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month=months[d.getMonth()];
    let date=d.getDate();
    const year="2022"
    const timer_date=month+" "+date+","+year+" "+time_s;

    let result = users.map(({ timer_date }) => timer_date)
    let exp_count=0;
    let act_count=0;


// console.table(result)
    for (let i = 0; i < result.length; i++) {
        // console.log()
        // if(result[i]){
        //     console.log(timer_date.)
        // }
        if(result[i]>timer_date){

            // if(t_d.getTime() > timer_date.getTime()){
                exp_count=exp_count+1;

            // }
            // console.log(exp_count)
        }
        else{
            act_count=act_count+1;
        }
    }
// console.table(len1)

    useEffect(() => {
        getDate()
        getDate1()
        // console.log(result)
        // console.table(timer_date)
    },[])


    return(<>
    <div className="container text-center mt-4">
    <p className="display-2 text-center"><img src="https://rgicdn.s3.ap-south-1.amazonaws.com/raisonimain/logo.webp" alt="rgi"/></p>
        <p className="h4 mb-4">Admin Dashboard</p>

        {/*stats*/}
        <p className="h5 text-center">Total student Registered:{len1}</p>
        <p className="h5 ">Total No. Of Students who started the competition: {len} </p>
        <p className="h5 ">Total No. Of Students whose time is currently active: {act_count-1} </p>
        <p className="h5 ">Total No. Of Students whose time is expired: {exp_count+1} </p>
        <div className="row gy-5">



            <div className="col-sm">
                <Link to="/admin/registered-students" className="card border p-4 cbtn admin-col">Registered Student List</Link>
            </div>
            <div className="col-sm">
                <Link to="/admin/registered-f_students" className="card border p-4 cbtn admin-col">Registered  Student List (foreign)</Link>
            </div>

            <div className="col-sm-6">
                <Link to="/admin/started_students" className="card border p-4 cbtn admin-col">Student List who started the competition</Link>
            </div>

            <div className="col-sm-6">
                <Link to="/admin/submitted-data" className="card border p-4 cbtn admin-col">Idea Submitted Data</Link>
            </div>

            <div className="col-sm">
                <Link to="/admin/aio_students" className="card border p-4 cbtn admin-col">All in One Student Data</Link>
            </div>
            {/* <div className="col-sm-6">
                <Link to="registered-students" className="card border p-4 cbtn admin-col">Testing 3</Link>
            </div>
            <div className="col-sm-6">
                <Link to="/submitted-data" className="card border p-4 cbtn admin-col">Testing 4</Link>
            </div> */}
        </div>
        </div>
    </>)
}
export default MenuDash;