import logo from "../Components/Assets/logo-tran.png"
const Footer = () => {
    return(<>




    <div className='text-center  footer' >
        <hr/>
            V Ideate U Validate <span>
            © {new Date().getFullYear()} All Rights Reserved{" "}
          </span> | Developed by <a className="c-link" href="https://www.cwipedia.com"><img src={logo}  className="img-fluid" style={{height:"40px"}} />CWIPEDIA</a>
          <hr/>
    </div>
    </>)
}
export default Footer;