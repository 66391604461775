
import {useEffect, useState} from "react"
import SecAnimation from '../../Animations/sec';

import {  useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2'



const Dashboard = ()=>{

    let navigate = useNavigate();

    
    let d = new Date();
    let time_s = d.toLocaleTimeString('en-GB');
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month=months[d.getMonth()];
    let date=d.getDate();
    date=date+3;
    const year="2022"
    const timer_date=month+" "+date+","+year+" "+time_s;



    async function handleStart(event){
        
        localStorage.setItem('start_timer',timer_date) //imp

    
        event.preventDefault()
        // const response= await fetch("http://localhost:5001/api/user-profile",

        const response= await fetch("https://api-cwipedia.herokuapp.com/api/user-profile",
        {
            method:'POST',
            headers:{
                'x-access-token':localStorage.getItem('token'),
                'email_id':localStorage.getItem('email'),
                'Content-Type': 'application/json'    
            }
        })
        const data= await response.json();
  
        if(data.status==='okay'){
            localStorage.setItem('username',data.user.name)
            localStorage.setItem('user_id',data.user._id)
            const time = new Date();


            const user_id=localStorage.getItem('user_id');

       
            async function handleTimer(event){
              // event.preventDefault()
              //   const response= await fetch("http://localhost:5001/api/user-time",

                const response= await fetch("https://api-cwipedia.herokuapp.com/api/user-time",
              {
                  method:'POST',
                  headers:{
                      'x-access-token':localStorage.getItem('token'),
                      'email_id':localStorage.getItem('email'),
                      'start_timer':localStorage.getItem('start_timer'),
                      'Content-Type': 'application/json'    
                  }
              })
            }



            async function getTimer(event){
              // event.preventDefault()
              //  api-cwipedia.herokuapp.com
              //          const response= await fetch("http://localhost:5001/api/login",
              const response = await fetch(`https://api-cwipedia.herokuapp.com/api/get_timer/${user_id}`, {
                method: 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                  }
                })
              const data= await response.json();

              localStorage.setItem('current_time',data.timer_date)


                if(data.status==='error-get-timer'){
                    Swal.fire(
                        {title:'Time is started',
                        text:'Click on Ok button to view your dashboard',
                        icon:'success',
                        confirmButtonColor: '#5ae4a7',
                        allowOutsideClick: false,
                        allowEscapeKey: false,}
                      ).then((result) => {
                        if (result.isConfirmed) {
                            navigate(`/dashboard/view`);
                        }
                      })

                }
            else{
                Swal.fire(
                    {title:'Time is already running',
                    text:'Click on Ok button to view your dahboard',
                    icon:'success',
                    confirmButtonColor: '#5ae4a7',
                    allowOutsideClick: false,
                    allowEscapeKey: false,}
                  ).then((result) => {
                    if (result.isConfirmed) {
                        navigate(`/dashboard/view`);
                    }
                  })

            }
                
          }
  
              handleTimer();
              getTimer();
  
              

        }
        else if(data.status==='error'){
            
        }



    }

    return(
        <>
        <div className="container mt-4">
            <div className="row">
                {/* <p className="h4">Hello {name}</p> */}
                <p className="display-2 text-center"><img src="https://rgicdn.s3.ap-south-1.amazonaws.com/raisonimain/logo.webp" alt="rgi" /></p>
                <div className="col-sm">
                    <div className="p-3 border rounded">
                        <p className="h4">Instructions:</p>
                        <p className="h5">Rules to follow during competition:</p>
                            <ul>
                                <li>The team has to select any one idea from the provided list covering different domains.</li>
                                <li>The total time for submission is 72 hours.</li>
                                <li>You must check all of the submitted Files</li>
                                <li>Make sure you  have stable internet connection</li>
                                <li><a href="https://ghrcem.raisoni.net/v-ideate-u-validate/#guide-registratn" >Read Guidelines</a></li>
                                <li><a href="https://ghrcem.raisoni.net/v-ideate-u-validate/#guide-registratn" >IDEA VALIDATION MATRIX</a></li>
                            </ul>
                        <p className="h4 blink">Once you click on the start button, your time will be start</p>

                        <button type="button"  className="btn cbtn" data-bs-toggle="modal"
                                data-bs-target="#user_p">
                            View Profile
                        </button>

                        <button onClick={handleStart} className="btn cbtn " >Start</button>



                        <div class="modal fade" id="user_p" tabindex="-1" aria-labelledby="user_p" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="user_p">User Profile</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body" style={{fontWeight:"bold"}}>
                                        <p>Team Name: {localStorage.getItem('user_team')}</p>
                                        <p>Lead Name: {localStorage.getItem('user_lead_name')}</p>
                                        <p>Teammate 1 Name: {localStorage.getItem('user_name1')}</p>
                                        <p>Teammate 2 Name: {localStorage.getItem('user_name2')}</p>
                                        <p>Teammate 3 Name: {localStorage.getItem('user_name3')}</p>
                                        <p>Selected Domain: {localStorage.getItem('user_domain')}</p>

                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="col-sm">
                    <div className="p-3 border rounded">
                        <SecAnimation />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Dashboard;