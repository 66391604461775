import offanim from "../Animations/107013-loader-for-wi-fi-connection.json";
import Lottie from "lottie-react";
import Footer from "./footer.component";



const OffMode = ()=> {

    return(
        <>
            <div className="container text-center">
                <div className="text-center m-2">
                    <div className="alert h3 alert-warning" role="alert">
                        You're offline right now. Check your connection.
                    </div>
                    <Lottie style={{height:"560px"}} loop={true} animationData={offanim} />

                </div>
            </div>
            <Footer/>
        </>
    )

}
export default OffMode;

