import { useState } from "react";
import {  Link,useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'


const Forgot = ()=>{
    let navigate = useNavigate();
    
    const [email, setEmail] = useState('');

    async function forgot(event){
        event.preventDefault()
       const response= await fetch("https://api-cwipedia.herokuapp.com/api/reset-password",
        {
            method:'POST',
            headers:{
                'Content-Type': 'application/json'    
            },
            body: JSON.stringify({
                email,
            }),
            
        })
        const data= await response.json();
        // console.log(data)

        if(data.status==='ok'){
            Swal.fire({
                title: 'Password Reset Successfull!',
                text: 'Please check your mail box for password',
                icon:'success',  
                confirmButtonColor: '#5ae4a7'
            })
            navigate(`/login`);
        }
        else if(data.status==='error-email'){
            Swal.fire(
                {title:'Email does not exist!',
                icon:'error',
                confirmButtonColor: '#5ae4a7'}
              )
              setEmail("")
        }
        else{
            Swal.fire(
               { title:'Failed!',
                text:'Please check email address',
                icon:'error', 
                confirmButtonColor: '#5ae4a7'}
              )
        }
        //to clear all field
        setEmail("")
        
    }

        return (<>
        <div className="login page-bg container text-center mt-4">
        <form className="center loginform" onSubmit={forgot}>
        <p className="display-5">Forgot Password?</p>
        <div className="form-floating mb-3">
            <input type="email" className="form-control" id="floatingInput" placeholder="abc@example.com"
            value={email}
            onChange={(e)=> setEmail(e.target.value)} />
            <label htmlFor="floatingInput">Email</label>
        </div>
        <div className="form-label">
            <Link to="/login">LogIn Now?</Link>
        </div>
        <button type="submit" className="btn cbtn mt-2" >Submit</button>
        </form>
        </div>
        </>);
    
}
 
export default Forgot;