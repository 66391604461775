


const Pay=()=>{


    return(
        <>
            <div className="container text-center">

                <p className="h4 mt-4">QR CODE TO SCAN
                </p>
                <img src="https://ghrcem.raisoni.net/v-ideate-u-validate/assets/images/qr-code.jpg"
                     className="img-fluid" />
            </div>
        </>
    )

}
export default Pay;