import {Component} from "react";
import Lottie from "lottie-react";
import pageNot from "../Animations/89922-404-page.json"
class PageNotFound extends Component {
    state = {  } 
    render() { 
        return (<>
            <div className="container NotFound text-center">
            <Lottie loop={true} animationData={pageNot} />

            </div>
        </>);
    }
}
 
export default PageNotFound;