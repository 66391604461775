import {useEffect,useState} from "react"
import CsvDownload from 'react-json-to-csv'
import {Link} from "react-router-dom";

const Aio_Stud = () => {

    const [users, setUsers] = useState([]);
    const [len, setLen] = useState();
    const [testdata, setTestdata] = useState([])
    const [withoutDuplicates, setWithoutDuplicates] = useState([])



    async function getStud(event){
        // event.preventDefault()
        const response= await fetch("https://api-cwipedia.herokuapp.com/api/admin/merge-stud");
        const data= await response.json();
        setTestdata(data)

        setUsers(data);

        setLen(data.length);
    }

    useEffect(() => {
        getStud()
        // console.log(testdata)



    },[])

    return(


        <>
            <h2 className="text-center">All in One Student Data  <p style={{color:"red"}}>(This page is under construction)</p></h2>
            <p className="h6 text-center">Total Student Registered:{len} </p>
             {/*<button onSubmit={getStud}>Data</button>*/}

            <div className="container">
                {/*<CsvDownload className="btn cbtn mb-2 " data={testdata}  filename="all-students.csv" >Download</CsvDownload>*/}


                <div className="row row-cols-2 row-cols-lg-2 g-2 g-lg-3">

                    {

                        users.map((ele)=>{

                            return(
                                <div>
                                    <div className="col">
                                        <div className="card shadow" >
                                            <div className="card-body">

                                                {   !ele.teamName? null:                                                 <h5 className="card-text"><b>Team Name:</b> {ele.teamName}</h5>
                                                }
                                                <h5 className="card-title"><b>Team Leader Name:</b> {ele.name}</h5>
                                                <p className="card-title"><b>Teammate 1 Name:</b> {ele.name1}</p>
                                                <p className="card-title"><b>Teammate 2 Name :</b> {ele.name2}</p>
                                                <p className="card-title"><b>Teammate 3 Name:</b> {ele.name3}</p>
                                                <p className="card-text"><b>Email:</b> {ele.email}</p>
                                                <p className="card-text"><b>Phone :</b> {ele.phone}</p>
                                                <p className="card-text"><b>Team Name:</b> {ele.teamName}</p>
                                                <p className="card-text"><b>Domain</b> {ele.domain}</p>
                                                <p className="card-text"><b>Class:</b> {ele.class_name}</p>
                                                <p className="card-text"><b>College:</b> {ele.college}</p>
                                                <p className="card-text"><b>College Address:</b> {ele.collegeAdd}</p>
                                                <p className="card-text"><b>Transaction Id:</b> {ele.trans_id}</p>


                                                {
                                                    !ele.timer_date? null: <p className="card-title"><b>Idea Submission Deadline:</b> {ele.timer_date}</p>
                                                }
                                                {
                                                    !ele.idea_id? null: <p className="card-title"><b>Idea Id:</b> {ele.idea_id}</p>
                                                }

                                                <p className="card-text"><b>IdCard:</b> <a href={ele.idUrl} className="btn btn-dark " >Download</a></p>
                                                <p className="card-text"><b>Payment SS:</b> <a href={ele.payUrl} className="btn btn-dark " >Download</a></p>

                                                {
                                                    !ele.ideaUrl? null:  <p className="card-text"><b>Idea:</b> <a href={ele.ideaUrl} className="btn btn-dark " >Download</a></p>
                                                }

                                                {
                                                    !ele.ideaOpUrl? null:  <p className="card-text"><b>Idea (Optional File):</b> <a href={ele.ideaOpUrl} className="btn btn-dark " >Download</a></p>
                                                }



                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )

                        })

                    }


                </div>
            </div>


        </>
    )
}
export default Aio_Stud;