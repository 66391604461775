import Lottie from "lottie-react";
import exam from "../Animations/exam.json";
import { Link } from "react-router-dom";
import College from "./college.component";



const Header=()=>{
    const token = localStorage.getItem('token');


    return(
        <>
        <div className="container text-center">
            <div className="row gx-5">
                <College/>
                <div className="col-sm">
                <Lottie style={{height:"560px"}} loop={true} animationData={exam} />
                </div>
                <div className="col-sm v-cen align-middle">
                    <p className="display-6">Believe in yourself, take on your challenges</p>
                            {
                                token? <Link to="/dashboard/view" className="btn cbtn m-2">View Dashboard</Link>:<Link to="/login" className="btn cbtn m-2">Log In</Link>
                            }
                            {
                                token? null:<Link to="/register" className="btn cbtn m-2">Register</Link>
                            }
                </div>
            </div>
        </div>
        </>
    )

}
export default Header;