import Header from './Components/header.component';

const Home =()=>{

    return(
        <>
            <Header/>        
        </>
    )
}
export default Home;