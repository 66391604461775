import {useEffect,useState} from "react"
import CsvDownload from "react-json-to-csv";


const StudStart = ()=>{

    const [users, setUsers] = useState([]);
    const [len, setLen] = useState();
    const [testdata, setTestdata] = useState([])

    async function getStud(event){
        // event.preventDefault()
        const response= await fetch("https://api-cwipedia.herokuapp.com/api/admin/get_timer");
        const data= await response.json();
        setUsers(data);
        setTestdata(data)

        setLen(data.length);

        // console.log(users.idea);
    }

    useEffect(() => {
        getStud();
    },[])

    return (<>

        <h2 className="text-center">Students who started the competition</h2>
        <p className="h6 text-center">Total No. Of Students who started the competition::{len}</p>


        {/* <button onSubmit={getStud}>Data</button> */}

        <div className="container">
            <CsvDownload className="btn cbtn mb-2 " data={testdata}  filename="Students_list.csv" >Download</CsvDownload>

            <div className="row row-cols-2 row-cols-lg-2 g-2 g-lg-3">


                {



                    users.map((ele)=>{

                        return(
                            <div>

                                <div className="col" key={ele.id}>
                                    <div className="card shadow" >
                                        <div className="card-body">
                                            <h5 className="card-title"><b>Team Leader Email:</b> {ele.email}</h5>
                                            <h5 className="card-title"><b>Idea Submission Deadline:</b> {ele.timer_date}</h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )

                    })

                }


            </div>
        </div>


    </>)
}
export default StudStart;