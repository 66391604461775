import {useEffect,useState} from "react"
import CsvDownload from 'react-json-to-csv'

const Students = () => {

    const [users, setUsers] = useState([]);
    const [len, setLen] = useState();
    const [testdata, setTestdata] = useState([])

    async function getStud(event){
        // event.preventDefault()
        const response= await fetch("https://api-cwipedia.herokuapp.com/api/admin/view_students");
        const data= await response.json();
        setTestdata(data)
        setUsers(data);

        setLen(data.length);
    }

    useEffect(() => {
        getStud()
        // console.log(testdata)
    },[])

    return(


        <>
            <h2 className="text-center">Registered Student</h2>
            <p className="h6 text-center">Total Student Registered:{len}</p>
            {/* <button onSubmit={getStud}>Data</button> */}

            <div className="container">
                <CsvDownload className="btn cbtn mb-2 " data={testdata}  filename="reg.csv" >Download</CsvDownload>

            {/* <table>
                <thead>
                    <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Enroll_No</th>
                    <th scope="col">Class</th>
                    <th scope="col">College</th>
                    </tr>
                </thead>
                <tbody>
            {

users.map((ele)=>{

    return(
        <div>

                <td >{ele.name}</td>
                <td>{ele.email}</td>
                <td>{ele.enroll_no}</td>
                <td>{ele.class_name}</td>
                <td>{ele.college_name}</td>


        </div>
    )

})

}
</tbody>
            </table> */}

                <div className="row row-cols-2 row-cols-lg-2 g-2 g-lg-3">

                    {

                        users.map((ele)=>{

                            return(
                                <div>
                                    <div className="col">
                                        <div className="card shadow" >
                                            <div className="card-body">
                                                <h5 className="card-text"><b>Team Name:</b> {ele.teamName}</h5>
                                                <h5 className="card-title"><b>Team Leader Name:</b> {ele.name}</h5>
                                                <p className="card-title"><b>Teammate 1 Name:</b> {ele.name1}</p>
                                                <p className="card-title"><b>Teammate 2 Name :</b> {ele.name2}</p>
                                                <p className="card-title"><b>Teammate 3 Name:</b> {ele.name3}</p>
                                                <p className="card-text"><b>Email:</b> {ele.email}</p>
                                                <p className="card-text"><b>Phone :</b> {ele.phone}</p>
                                                <p className="card-text"><b>Team Name:</b> {ele.teamName}</p>
                                                <p className="card-text"><b>Domain</b> {ele.domain}</p>
                                                <p className="card-text"><b>Class:</b> {ele.class_name}</p>
                                                <p className="card-text"><b>College:</b> {ele.college}</p>
                                                <p className="card-text"><b>College Address:</b> {ele.collegeAdd}</p>

                                                {
                                                    ele.trans_id.includes("Student")?  <p className="card-text"><b>Student Type:</b> Foreign Student</p> :  <p className="card-text"><b>Transaction Id:</b> {ele.trans_id}</p>
                                                }


                                                <p className="card-text"><b>IdCard:</b> <a href={ele.idUrl} className="btn btn-dark " >Download</a></p>

                                                {
                                                    ele.payUrl.includes("Student")? null:  <p className="card-text"><b>Payment SS:</b> <a href={ele.payUrl} className="btn btn-dark " >Download</a></p>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )

                        })

                    }


                </div>
                </div>


        </>
    )
}
export default Students;