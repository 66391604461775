import { useState,useEffect } from "react";
import {  useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

const AdminLogin= ()=>{

    let navigate = useNavigate();


    const [email, setEmail] = useState('');
    const [psw, setPsw] = useState('');

    useEffect(() => {

	}, [])  

    async function login(event){
        event.preventDefault()
       const response= await fetch("https://api-cwipedia.herokuapp.com/api/admin/login",
        {
            method:'POST',
            headers:{
                'x-access-token':localStorage.getItem('token'),
                'Content-Type': 'application/json'    
            },
            body: JSON.stringify({
                email,
                psw,
            }),
            
        })
        const data= await response.json();
        // console.log(data)

        if(data.status==='okay'){
            localStorage.setItem('token',data.user);

            Swal.fire(
                {title:'Admin Login Successful!',
                text:'Please read all Instructions carefully!',
                icon:'success',
                confirmButtonColor: '#5ae4a7'}
              ).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload(true);
                }
              })
            navigate(`/admin/dashboard`);
        }
        else if(data.status==='error'){
            Swal.fire(
                {title:'Login Failed!',
                text:'Please check email or password!',
                icon:'error',
                confirmButtonColor: '#5ae4a7'}
              )
        }
        //to clear all field
        setEmail("");
        setPsw("")
        
    }

    return(
        <div className="login container page-bg text-center mt-4">
        <form className="center loginform" onSubmit={login}>
        <p className="display-5">Admin Login</p>
        <div className="form-floating mb-3">
            <input type="email" className="form-control" id="floatingInput" placeholder="abc@example.com"
            value={email}
            onChange={(e)=> setEmail(e.target.value)} />
            <label htmlFor="floatingInput">Email</label>
        </div>
        <div className="form-floating">
            <input type="password" className="form-control" id="floatingPassword" placeholder="Password" 
            value={psw}
            onChange={(e)=> setPsw(e.target.value)} />
            <label htmlFor="floatingPassword">Password</label>
        </div>
        <button type="submit" className="btn cbtn mt-2" >Login</button>
        </form>
        </div>
    )
}
export default AdminLogin;