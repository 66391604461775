import {useEffect,useState} from "react"
import CsvDownload from "react-json-to-csv";


const SubStud = ()=>{

    const [users, setUsers] = useState([]);
    const [len, setLen] = useState();
    const [testdata, setTestdata] = useState([])

    async function getStud(event){
        // event.preventDefault()
        const response= await fetch("https://api-cwipedia.herokuapp.com/api/admin/view_submitted");
        const data= await response.json();
        setUsers(data);
        setTestdata(data)

        setLen(data.length);

        // console.log(users.idea);
    }

    useEffect(() => {
        getStud();
    },[])

    return (<>

        <h2 className="text-center">Student Ideas</h2>
        <p className="h6 text-center">Total Student Registered:{len}</p>

        
            {/* <button onSubmit={getStud}>Data</button> */}

            <div className="container">
                <CsvDownload className="btn cbtn mb-2 " data={testdata}  filename="Students.csv" >Download</CsvDownload>

                <div className="row row-cols-2 row-cols-lg-2 g-2 g-lg-3">


                    {



                        users.map((ele)=>{
                            
                            return(
                                <div>

                                    <div className="col" key={ele.id}>
                                        <div className="card shadow" >
                                            <div className="card-body">
                                                <h5 className="card-text"><b>Team Name:</b> {ele.teamName}</h5>
                                                <h5 className="card-title"><b>Selected Idea:</b> {ele.idea_id}</h5>
                                                <h5 className="card-title"><b>Team Leader Name:</b> {ele.name}</h5>
                                                <p className="card-title"><b>Teammate 1 Name:</b> {ele.name1}</p>
                                                <p className="card-title"><b>Teammate 2 Name :</b> {ele.name2}</p>
                                                <p className="card-title"><b>Teammate 3 Name:</b> {ele.name3}</p>
                                                <p className="card-text"><b>Team Leader Email:</b> {ele.email}</p>
                                                <p className="card-text"><b>Phone:</b> {ele.phone}</p>
                                                <p className="card-text"><b>Domain</b> {ele.domain}</p>
                                                <p className="card-text"><b>Class:</b> {ele.class_name}</p>
                                                <p className="card-text"><b>College:</b> {ele.college}</p>
                                                <p className="card-text"><b>College Address:</b> {ele.collegeAdd}</p>
                                                {/*<p className="card-text"><b>Transaction Id:</b> {ele.trans_id}</p>*/}
                                                <p className="card-text"><b>Idea:</b> <a href={ele.ideaUrl} className="btn btn-dark " >Download</a></p>
                                                {
                                                    !ele.ideaOpUrl? null:  <p className="card-text"><b>Idea (Optional File):</b> <a href={ele.ideaOpUrl} className="btn btn-dark " >Download</a></p>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )

                        })

                    }

                   
                </div>
                </div>


    </>)
}
export default SubStud;