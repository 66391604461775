import examlogin from "./78126-secure-login.json"
 
import Lottie from "lottie-react";

 const SecAnimation= ()=>{
     return(
         <>
                        <Lottie style={{height:"360px"}} loop={true} animationData={examlogin} />
        
         </>
     )
 }

 export default SecAnimation;