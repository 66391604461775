import './App.css';
import {useEffect} from "react"
import Navbar from './Components/navbar.component';
import {BrowserRouter as Router, Routes, Route,} from 'react-router-dom';
import Home from './home';
import Login from './Components/Pages/login.page';
import CreatAccount from "./Components/Pages/createacc.page"
import Dashboard from "./Components/Pages/dashboard.page"
import LinkPage from './Components/Pages/link.page';
import Forgot from './Components/Pages/forgot.page';
import Footer from './Components/footer.component';
import MenuDash from './Admin/menu.admin';
import PageNotFound from './Components/404.component';
import ProtectedRoutes from './Components/routes/ProtectedRoute';
import AdminLogin from './Admin/login.admin';
import SubStud from './Admin/sub_stud.admin';
import Students from './Admin/studs.admin';
import SubDash from "./Components/Pages/subdash.page";

import { Offline, Online } from "react-detect-offline";
import OffMode from "./Components/off.mode";
import StudStart from "./Admin/studs_start.admin";
import PayPage from "./Components/Pages/pay.page";
import Pay from "./Components/Pages/pay.page";
import F_Students from "./Admin/f_studs.admin";
import Aio_Stud from "./Admin/aio_stud.admin";
import Close from "./Components/close.component";

function App() {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
  return (
    <div className="App">
       <Router>
           <Online>
        <Navbar/>

          <Routes>
                <Route path="/" exact element={<Home/>}/>
                <Route path="/login" exact element={<Login/>}/>
                <Route path="/register/closed" exact element={<CreatAccount/>}/>
              <Route path="/register" exact element={<Close/>}/>
                <Route path="/reset-password" exact element={<Forgot/>}/>
              <Route path="/payment" exact element={<Pay/>}/>

                <Route path="/admin/login" exact element={<AdminLogin/>}/>
                

                <Route path="/" exact element={<ProtectedRoutes/>}>

                <Route path="/dashboard" exact element={<Dashboard/> }/>
                <Route path="/dashboard/view" exact element={<LinkPage/>}/>
                    <Route path="/test" exact element={<SubDash/>}/>

                <Route path="/admin/dashboard" exact element={<MenuDash/>}/>
                <Route path="/admin/submitted-data" exact element={<SubStud/>}/>
                <Route path="/admin/registered-students" exact element={<Students/>}/><Route path="/admin/registered-f_students" exact element={<F_Students/>}/>
                <Route path="/admin/started_students" exact element={<StudStart/>}/>
                    <Route path="/admin/aio_students" exact element={<Aio_Stud/>}/>
                </Route> 
               
                
                <Route path="*"  element={<PageNotFound />} />

                {/* testing */}
          </Routes>

          <Footer/>

          </Online>

           <Offline>

             <OffMode/>

           </Offline>

       </Router>

    </div>
  );
}

export default App;
