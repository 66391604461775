import logo from "./Assets/logo.png"
import { Link } from 'react-router-dom';
import Logout from "./logout.component";

const Navbar =()=> {

    const token = localStorage.getItem('token');

        
    
        return (
            <>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/"><img  className="logo" alt="RGI Pune" title="RGI Pune" src={logo}  /> V Ideate U Validate</a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                            <a className="nav-link active m-2" aria-current="page" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                            <a className="nav-link m-2" href="https://ghrcem.raisoni.net/v-ideate-u-validate/#guide-registratn">Contact</a>
                            </li>
                            <li className="nav-item">
                            <a className="nav-link m-2" href="https://ghrcem.raisoni.net/v-ideate-u-validate/#guide-registratn">About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link m-2" href="https://wa.me/7038737772">Technical Support</a>
                            </li>
                            <li className="nav-item">
                                <Link to="/payment" className="nav-link m-2">Payment</Link>
                            </li>
                            {
                                token? <Logout/>: <li className="nav-item">
                                <Link to="/login" className="btn cbtn m-2">Log In</Link>
                            </li>
                            }
                             {
                                token? null:<li className="nav-item">
                                <Link to="/register" className="btn cbtn m-2">Register</Link>
                            </li>
                            }
                            
                        </ul>
                        </div>
                    </div>
                    </nav>
            </>
        );
    
}
 
export default Navbar;