import { useState } from "react";
import {  Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Create= ()=>{
    let navigate = useNavigate();
    const [studType, setStudType] = useState('🇮🇳 Indian');
    const [name, setName] = useState('');
    const [name1, setName1] = useState('');
    const [name2, setName2] = useState('');
    const [name3, setName3] = useState('');
    const [email, setEmail] = useState('');
    const [psw, setPsw] = useState('');
    const [trans_id,setTrans_id] =useState("");
    const [phone, setPhone] = useState('');
    const [teamName, setTeamName] = useState('');
    const [domain, setDomain] = useState('');
    const [class_name, setClass_name] = useState('');
    const [college, setCollege] = useState('');
    const [collegeAdd, setCollegeAdd] = useState('');
    const [idCard, setIdcard] = useState('');
    const [paySs, setPaySs] = useState('');
    const [uploadText, setUploadText] = useState('Click here to Upload');
    const [uploadText0, setUploadText0] = useState('Click here to Upload');
    const [idUrl, setIdUrl] = useState('');
    const [payUrl, setPayUrl] = useState('');


    async function SendS3(event){
        event.preventDefault();

        toast("Please wait, Id is uploading!", {
            position: "top-right",
            autoClose: 1000,
            type:"info",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        const formData= new FormData()
        formData.append('idcard',idCard)
        // const res= await fetch("http://localhost:5001/api/id/upload",
        const res=await fetch("https://api-cwipedia.herokuapp.com/api/id/upload",
            {
            method: 'POST',
            body: formData
        })
        // console.log("response: ", res);
        const data= await res.json();
        // console.log(data.file)
        if(data.status==='okay'){
            setUploadText("Uploaded")
            toast("Uploaded!", {
                position: "top-right",
                autoClose: 2000,
                type:"success",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIdUrl(data.file)
        }
        else if(data.status==='error'){

        }

      };

    async function SendS3_Pay(event){
        event.preventDefault();
        toast("Please wait, Payment Screenshot is uploading!", {
            position: "top-right",
            autoClose: 1000,
            type:"info",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        const formData= new FormData()
        formData.append('Payment',paySs)
        // const res= await fetch("http://localhost:5001/api/pay/upload",
        const res=await fetch("https://api-cwipedia.herokuapp.com/api/pay/upload",
            {
            method: 'POST',
            body: formData
        })
        // console.log("response: ", res);
        const data= await res.json();
        // console.log(data.file)
        if(data.status==='okay'){
            setUploadText0("Uploaded")
            toast("Uploaded!", {
                position: "top-right",
                autoClose: 2000,
                type:"success",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setPayUrl(data.file)
        }
        else if(data.status==='error'){

        }
        // else if(data.status==='pending'){
        //     Swal.fire(
        //         {title:'Please wait,',
        //             text:'Image is uploading!',
        //             icon:'warning',
        //             confirmButtonColor: '#5ae4a7'}
        //     )
        // }

      };

    async function registerF_stud(event){
        event.preventDefault()
        if(uploadText=="Uploaded" ){
            // const response= await fetch("http://localhost:5001/api/register",
            const response= await fetch("https://api-cwipedia.herokuapp.com/api/register",
                {
                    method:'POST',
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        teamName,
                        name,
                        name1,
                        name2,
                        name3,
                        email,
                        psw,
                        phone,
                        domain,
                        class_name,
                        college,
                        collegeAdd,
                        trans_id:"Foreign Student"+name,
                        idUrl,
                        payUrl:"Foreign Student",
                    }),

                })
            const data= await response.json();
            // console.log(data)

            if(data.status==='Okay'){
                Swal.fire(
                    {title:'Registration Successful!',
                        text:'Please login and continue',
                        icon:'success',
                        confirmButtonColor: '#5ae4a7'}
                )
                navigate(`/login`);
                //to clear all field
                setName("")
                setName1("")
                setName2("")
                setName3("")
                setEmail("")
                setPsw("")
                setPhone("")
                setDomain("")
                setCollege("")
                setTrans_id("")
                setCollegeAdd("")
                setClass_name("")
                setIdUrl("")
                setTeamName("")
                setPayUrl("")
                setIdcard(null)
                setPaySs(null)
            }
            else if(data.status==='error-psw'){
                Swal.fire(
                    {title:'Registration Failed!',
                        text:'Password should be minimum 8 character or more',
                        icon:'error',
                        confirmButtonColor: '#5ae4a7'}
                )
                setPsw("")

            }
            else if(data.status==='error-email'){
                Swal.fire(
                    {title:'Email Already Registered!',
                        text:'Please check all details!',
                        icon:'error',
                        confirmButtonColor: '#5ae4a7'}
                )
                setEmail("")
            }
            else if(data.status==='error-team'){
                Swal.fire(
                    {title:'Team Name Already Exist!',
                        text:'Please change Team name !',
                        icon:'error',
                        confirmButtonColor: '#5ae4a7'}
                )
                setTeamName("")
            }
            else if(data.status==='error'){
                Swal.fire(
                    {title:'Registration Failed!',
                        text:'Please check all details!',
                        icon:'error',
                        confirmButtonColor: '#5ae4a7'}
                )
                setName1("")
                setName2("")
                setName3("")
                setName("")
                setEmail("")
                setPsw("")
                setPhone("")
                setTeamName("")
                setTrans_id("")
                setDomain("")
                setCollege("")
                setCollegeAdd("")
                setClass_name("")
                setIdUrl("")
                setPayUrl("")
                setIdcard(null)
                setPaySs(null)

            }
        }
        else{
            Swal.fire(
                {title:'First you need to upload',
                    text:'Please try again',
                    icon:'error',
                    confirmButtonColor: '#5ae4a7'}
            )
        }

    }
        
      async function registerUser(event){
        event.preventDefault()
        if(uploadText=="Uploaded" &&  uploadText0=="Uploaded"){
           // const response= await fetch("http://localhost:5001/api/register",
            const response= await fetch("https://api-cwipedia.herokuapp.com/api/register",
        {
            method:'POST',
            headers:{
                'Content-Type': 'application/json'    
            },
            body: JSON.stringify({
                teamName,
                name,
                name1,
                name2,
                name3,
                email,
                psw,
                phone,
                domain,
                class_name,
                college,
                collegeAdd,
                trans_id,
                idUrl,
                payUrl,
            }),
            
        })
        const data= await response.json();
        // console.log(data)

        if(data.status==='Okay'){
            Swal.fire(
                {title:'Registration Successful!',
                text:'Please login and continue',
                icon:'success',
                confirmButtonColor: '#5ae4a7'}
              )
            navigate(`/login`);
            //to clear all field
            setName("")
            setName1("")
            setName2("")
            setName3("")
            setEmail("")
            setPsw("")
            setPhone("")
            setDomain("")
            setCollege("")
            setTrans_id("")
            setCollegeAdd("")
            setClass_name("")
            setIdUrl("")
            setTeamName("")
            setPayUrl("")
            setIdcard(null)
            setPaySs(null)
        }
        else if(data.status==='error-psw'){
            Swal.fire(
                {title:'Registration Failed!',
                text:'Password should be minimum 8 character or more',
                icon:'error',
                confirmButtonColor: '#5ae4a7'}
              )
            setPsw("")

        }
        else if(data.status==='error-email'){
            Swal.fire(
                {title:'Email Already Registered!',
                text:'Please check all details!',
                icon:'error',
                confirmButtonColor: '#5ae4a7'}
              )
              setEmail("")
        }
        else if(data.status==='error-team'){
            Swal.fire(
                {title:'Team Name Already Exist!',
                text:'Please change Team name !',
                icon:'error',
                confirmButtonColor: '#5ae4a7'}
              )
             setTeamName("")
        }
        else if(data.status==='error'){
            Swal.fire(
                {title:'Registration Failed!',
                text:'Please check all details!',
                icon:'error',
                confirmButtonColor: '#5ae4a7'}
              )
            setName1("")
            setName2("")
            setName3("")
              setName("")
              setEmail("")
              setPsw("")
              setPhone("")
              setTeamName("")
            setTrans_id("")
              setDomain("")
              setCollege("")
              setCollegeAdd("")
              setClass_name("")
              setIdUrl("")
              setPayUrl("")
              setIdcard(null)
              setPaySs(null)

        }
        }
        else{
            Swal.fire(
                {title:'First you need to upload',
                text:'Please try again',
                icon:'error',
                confirmButtonColor: '#5ae4a7'}
              )
        }
       
    }


    return(<>
    {/* was-validated */}
     <div className="login page-bg container text-center mt-4 ">

         <p className="h5">V Ideate U Validate</p>
         <p className="display-5">Create Account</p>
         <p> {studType} student Form</p>

         <div className="row g-3 align-items-center">
             <div className="col-auto">
                 <label id="pay" className="form-label ml-1 h5">Select Student Type:
                 </label>
             </div>
             <div className="col">
                 <select className="form-select mb-3" value={studType}
                         onChange={(e)=> setStudType(e.target.value)} >
                     {/*<option selected >Open this select Student type</option>*/}
                     <option value="Indian">
                         🇮🇳 Indian</option>
                     <option value="Foreign">🌐 Foreign</option>
                 </select>
             </div>
         </div>


         { studType=="Foreign" ?   <form className="center loginform" onSubmit={registerF_stud} >
                 <div className="form-floating mb-3">
                     <input type="text" className="form-control" id="floatingInput4" placeholder="20202020230"
                            value={teamName}
                            onChange={(e)=> setTeamName(e.target.value)} required/>
                     <label htmlFor="floatingInput4">Team Name</label>
                 </div>

                 <div className="form-floating mb-3">
                     <input type="name" className="form-control" id="floatingInput1" placeholder="abc"
                            value={name}
                            onChange={(e)=> setName(e.target.value)} required
                     />
                     <label htmlFor="floatingInput1">Team Leader Name</label>
                 </div>

                 <div className="form-floating mb-3">
                     <input type="name" className="form-control" id="floatingInput1" placeholder="abc"
                            value={name1}
                            onChange={(e)=> setName1(e.target.value)}
                     />
                     <label htmlFor="floatingInput1">Teammate 1 Name (Optional)</label>
                 </div>

                 <div className="form-floating mb-3">
                     <input type="name" className="form-control" id="floatingInput1" placeholder="abc"
                            value={name2}
                            onChange={(e)=> setName2(e.target.value)}
                     />
                     <label htmlFor="floatingInput1">Teammate 2 Name (Optional)</label>
                 </div>

                 <div className="form-floating mb-3">
                     <input type="name" className="form-control" id="floatingInput1" placeholder="abc"
                            value={name3}
                            onChange={(e)=> setName3(e.target.value)}
                     />
                     <label htmlFor="floatingInput1">Teammate 3 Name (Optional)</label>
                 </div>


                 <div className="form-floating mb-3">
                     <input type="email" className="form-control" id="floatingInput2" placeholder="abc@example.com"
                            value={email}
                            onChange={(e)=> setEmail(e.target.value)}  required/>
                     <label htmlFor="floatingInput2">Team Leader Email</label>
                 </div>

                 <div className="form-floating mb-3">
                     <input type="password" className="form-control" id="floatingPassword" placeholder="Password"
                            value={psw}
                            onChange={(e)=> setPsw(e.target.value)} required/>
                     <label htmlFor="floatingPassword">Password</label>
                 </div>

                 <div className="form-floating mb-3">
                     <input type="number" className="form-control" id="floatingInput3" placeholder="20202020230"
                            value={phone}
                            onChange={(e)=> setPhone(e.target.value)} required />
                     <label htmlFor="floatingInput3">Mobile Number</label>
                 </div>

                 <div className="form-floating">
                     <select className="form-select" required id="floatingSelect1"
                             value={domain}
                             onChange={(e)=> setDomain(e.target.value)}>
                         <option value="">Open this select your domain </option>
                         <option value="IoT">Internet of Things</option>
                         <option value="AIML">AI/ML</option>
                         <option value="Blockchain">Blockchain</option>
                         <option value="Electric Vehicle">Electric Vehicle</option>
                         <option value="Cyber Security">Cyber Security</option>
                         <option value="Drones">Drones</option>
                         <option value="Robotics">Robotics</option>
                         <option value="Cloud Computing">Cloud Computing</option>
                         <option value="Others">Others</option>
                     </select>
                     <label htmlFor="floatingSelect1">Domains</label>
                 </div>

                 <div className="form-floating mt-3">
                     <select className="form-select" required id="floatingSelect"
                             value={class_name}
                             onChange={(e)=> setClass_name(e.target.value)}>
                         <option value="">In which year you are now</option>
                         <option value="FE">First Year of Engineering/Technology</option>
                         <option value="SE">Second Year of Engineering/Technology</option>
                         <option value="TE">Third Year of Engineering/Technology</option>
                         <option value="BE">Final Year of Engineering/Technology</option>
                     </select>
                     <label htmlFor="floatingSelect">Select any one</label>
                 </div>

                 <div className="form-floating mt-3 mb-3">
                     <input type="text" className="form-control" id="floatingInput5" placeholder="20202020230"
                            value={college}
                            onChange={(e)=> setCollege(e.target.value)} required/>
                     <label htmlFor="floatingInput5">College Name</label>
                 </div>

                 <div className="form-floating mb-3">
                     <input type="text" className="form-control" id="floatingInput6" placeholder="20202020230"
                            value={collegeAdd}
                            onChange={(e)=> setCollegeAdd(e.target.value)} required/>
                     <label htmlFor="floatingInput6">College Address</label>
                 </div>

                 {/*<div className="input-group mt-3">*/}
                 {/*    <label id="pay" className="form-label ml-1">(For payment : <Link to="/payment">Click here</Link> )*/}
                 {/*    </label>*/}
                 {/*</div>*/}
                 {/*<div className="form-floating mb-3">*/}
                 {/*    <input type="text" className="form-control" id="floatingInput6" placeholder="20202020230"*/}
                 {/*           value={trans_id}*/}
                 {/*           onChange={(e)=> setTrans_id(e.target.value)} required/>*/}
                 {/*    <label htmlFor="floatingInput6">Transaction Id</label>*/}
                 {/*</div>*/}

                 <div className="mt-3">
                     <div className="input-group mt-3">
                         <label id="idcard" className="form-label ">Upload College ID card:--</label>
                         <div className="input-group ">
                             <input type="file" name="idcard" className="form-control" id="idcard"
                                    onChange={(e) => setIdcard(e.target.files[0])} required />
                             <button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon041" onClick={SendS3}>{uploadText}</button>
                         </div>
                     </div>
                 </div>

                 {/*<div className="mt-3">*/}
                 {/*    <div className="input-group mt-3">*/}
                 {/*        <label id="pay" className="form-label ml-1">Upload Payment Screen shot:*/}
                 {/*        </label>*/}
                 {/*        <div className="input-group ">*/}
                 {/*            <input type="file" name="Payment" className="form-control" id="pay"*/}
                 {/*                   onChange={(e) => setPaySs(e.target.files[0])} required/>*/}
                 {/*            <button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04" onClick={SendS3_Pay}>{uploadText0}</button>*/}
                 {/*        </div>*/}
                 {/*    </div>*/}
                 {/*</div>*/}

                 <button type="submit" className="btn cbtn mt-2" >Register</button>
                 <p className="white">Already have an account?
                     <Link to="/login" className="white"> Log In</Link></p>
             </form> :

//indian student
        <form className="center loginform" onSubmit={registerUser} >
            <div className="form-floating mb-3">
                <input type="text" className="form-control" id="floatingInput4" placeholder="20202020230"
                       value={teamName}
                       onChange={(e)=> setTeamName(e.target.value)} required/>
                <label htmlFor="floatingInput4">Team Name</label>
            </div>

            <div className="form-floating mb-3">
                <input type="name" className="form-control" id="floatingInput1" placeholder="abc"
                value={name}
                onChange={(e)=> setName(e.target.value)} required
                />
                <label htmlFor="floatingInput1">Team Leader Name</label>
            </div>

            <div className="form-floating mb-3">
                <input type="name" className="form-control" id="floatingInput1" placeholder="abc"
                       value={name1}
                       onChange={(e)=> setName1(e.target.value)}
                />
                <label htmlFor="floatingInput1">Teammate 1 Name (Optional)</label>
            </div>

            <div className="form-floating mb-3">
                <input type="name" className="form-control" id="floatingInput1" placeholder="abc"
                       value={name2}
                       onChange={(e)=> setName2(e.target.value)}
                />
                <label htmlFor="floatingInput1">Teammate 2 Name (Optional)</label>
            </div>

            <div className="form-floating mb-3">
                <input type="name" className="form-control" id="floatingInput1" placeholder="abc"
                       value={name3}
                       onChange={(e)=> setName3(e.target.value)}
                />
                <label htmlFor="floatingInput1">Teammate 3 Name (Optional)</label>
            </div>


            <div className="form-floating mb-3">
                <input type="email" className="form-control" id="floatingInput2" placeholder="abc@example.com"
                value={email}
                onChange={(e)=> setEmail(e.target.value)}  required/>
                <label htmlFor="floatingInput2">Team Leader Email</label>
            </div>

            <div className="form-floating mb-3">
                <input type="password" className="form-control" id="floatingPassword" placeholder="Password"
                value={psw}
                onChange={(e)=> setPsw(e.target.value)} required/>
                <label htmlFor="floatingPassword">Password</label>
            </div>

            <div className="form-floating mb-3">
                <input type="number" className="form-control" id="floatingInput3" placeholder="20202020230"
                value={phone}
                onChange={(e)=> setPhone(e.target.value)} required />
                <label htmlFor="floatingInput3">Mobile Number</label>
            </div>

            <div className="form-floating">
                <select className="form-select" required id="floatingSelect1" 
                value={domain}
                onChange={(e)=> setDomain(e.target.value)}>
                    <option value="">Open this select your domain </option>
                    <option value="IoT">Internet of Things</option>
                    <option value="AIML">AI/ML</option>
                    <option value="Blockchain">Blockchain</option>
                    <option value="Electric Vehicle">Electric Vehicle</option>
                    <option value="Cyber Security">Cyber Security</option>
                    <option value="Drones">Drones</option>
                    <option value="Robotics">Robotics</option>
                    <option value="Cloud Computing">Cloud Computing</option>
                    <option value="Others">Others</option>
                </select>
                <label htmlFor="floatingSelect1">Domains</label>
            </div>
    
            <div className="form-floating mt-3">
                <select className="form-select" required id="floatingSelect" 
                value={class_name}
                onChange={(e)=> setClass_name(e.target.value)}>
                    <option value="">In which year you are now</option>
                    <option value="FE">First Year of Engineering/Technology</option>
                    <option value="SE">Second Year of Engineering/Technology</option>
                    <option value="TE">Third Year of Engineering/Technology</option>
                    <option value="BE">Final Year of Engineering/Technology</option>
                </select>
                <label htmlFor="floatingSelect">Select any one</label>
            </div>

            <div className="form-floating mt-3 mb-3">
                <input type="text" className="form-control" id="floatingInput5" placeholder="20202020230"
                value={college}
                onChange={(e)=> setCollege(e.target.value)} required/>
                <label htmlFor="floatingInput5">College Name</label>
            </div>

            <div className="form-floating mb-3">
                <input type="text" className="form-control" id="floatingInput6" placeholder="20202020230"
                value={collegeAdd}
                onChange={(e)=> setCollegeAdd(e.target.value)} required/>
                <label htmlFor="floatingInput6">College Address</label>
            </div>
            <div className="input-group mt-3">
            <label id="pay" className="form-label ml-1">(For payment : <Link to="/payment">Click here</Link> )
            </label>
            </div>
            <div className="form-floating mb-3">
                <input type="text" className="form-control" id="floatingInput6" placeholder="20202020230"
                       value={trans_id}
                       onChange={(e)=> setTrans_id(e.target.value)} required/>
                <label htmlFor="floatingInput6">Transaction Id</label>
            </div>

            <div className="mt-3">
            <div className="input-group mt-3">
                <label id="idcard" className="form-label ">Upload College ID card:--</label>
                <div className="input-group ">
                <input type="file" name="idcard" className="form-control" id="idcard" 
                onChange={(e) => setIdcard(e.target.files[0])} required />
                <button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon041" onClick={SendS3}>{uploadText}</button>
                </div>
            </div>
            </div>
           
            <div className="mt-3">
            <div className="input-group mt-3">
                <label id="pay" className="form-label ml-1">Upload Payment Screen shot:
                </label>
                <div className="input-group ">
                <input type="file" name="Payment" className="form-control" id="pay" 
               onChange={(e) => setPaySs(e.target.files[0])} required/>
                <button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04" onClick={SendS3_Pay}>{uploadText0}</button>
            </div>
            </div>
            </div>

            <button type="submit" className="btn cbtn mt-2" >Register</button>
            <p className="white">Already have an account?
            <Link to="/login" className="white"> Log In</Link></p>
        </form>
         }

        </div>
        <ToastContainer />
    </>)
}

export default Create;