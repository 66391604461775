import logo from "./Assets/logo.png"
import logow from "./Assets/logo.webp"
const College = () => {
    return(
        <>
<div className="card college border-0">
  <div className="card-body">
            <p className="card-title h4"> <img  className="logo" src={logo}  /> G H RAISONI COLLEGE OF ENGINEERING & MANAGEMENT, WAGHOLI, PUNE <img src="https://rgicdn.s3.ap-south-1.amazonaws.com/raisonimain/logo.webp" className="logo" alt="rgi" /></p>
            <small>An Autonomous Institute, Affiliated to Savitribai Phule Pune University</small>
  </div>
</div>
      
        
        </>
    )
}
export default College