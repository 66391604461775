import React from 'react';
import Swal from 'sweetalert2';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import $ from "jquery";
import Logout from '../logout.component';
import ideaFile from '../Assets/National-Competition-2022.pdf'
import Countdown from "react-countdown";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LinkPage= (props)=>{

  let navigate = useNavigate();

  const [idea,setIdea] = useState('')
  const [ideaUrl,setIdeaUrl] = useState('')
  const [ideaOpUrl,setIdeaOpUrl] = useState('')
  let [idea_id,setIdea_id] = useState('')
  const [ideaOp,setIdeaOp] = useState('')
  const [uploadText, setUploadText] = useState('Click here to Upload');
  const [uploadText0, setUploadText0] = useState('Click here to Upload');
  const user_id=localStorage.getItem('user_id');
  const [l_time,setLTime]=useState([]);


const alter_box=()=>{
  Swal.fire({
    icon: 'warning',
    title: 'Keep eye on time',
    confirmButtonColor: '#5ae4a7',
    allowOutsideClick: false,
    allowEscapeKey: false,
  })
}
//random excute
setTimeout(alter_box,3600000)
 
useEffect(() => {

});

    const AlertFun=()=>{
      Swal.fire({
        icon: 'warning',
        title: 'Oops... Warning',
        text: 'not allowed!',
        confirmButtonColor: '#5ae4a7',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }) 
    }

    const u_name=localStorage.getItem('username');

  async function sendIdea(event){
      event.preventDefault();
      toast("Please wait,It's uploading!", {
          position: "top-right",
          autoClose: 1000,
          type:"info",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
      const formData= new FormData()
      formData.append('idea',idea)
      const res=await fetch("https://api-cwipedia.herokuapp.com/api/idea1/upload", {
          method: 'POST',
          body: formData
      })
      // console.log("response: ", res);
      const data= await res.json();
      // console.log(data.file)
      if(data.status==='okay'){
          toast("Uploaded!", {
              position: "top-right",
              autoClose: 2000,
              type:"success",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          setUploadText("Uploaded")
          setIdeaUrl(data.file)
      }
      else if(data.status==='error'){

      }

    };
  async function sendIdeaOp(event){
      event.preventDefault();

      const formData= new FormData()
      formData.append('ideaOp',ideaOp)
      const res=await fetch("https://api-cwipedia.herokuapp.com/api/idea2/upload", {
          method: 'POST',
          body: formData
      })

      toast("Please wait,It's uploading!", {
          position: "top-right",
          autoClose: 1000,
          type:"info",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
      // console.log("response: ", res);
      const data= await res.json();
      // console.log(data.file)
      if(data.status==='okay'){

          setUploadText0("Uploaded")
          toast("Uploaded!", {
              position: "top-right",
              autoClose: 2000,
              type:"success",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          setIdeaOpUrl(data.file)
      }
      else if(data.status==='error'){

      }

    };

    async function handleIdea(event){
      event.preventDefault()
        idea_id=u_name+"_"+idea_id;
    
        const response= await fetch("https://api-cwipedia.herokuapp.com/api/user-idea",
        {
            method:'POST',
            headers:{
                'x-access-token':localStorage.getItem('token'),
                'email_id':localStorage.getItem('email'),
                'Content-Type': 'application/json'    
            },
            body: JSON.stringify({
                // _id,
                idea_id,
                ideaUrl,
                ideaOpUrl,
            }),
            
        })
        const data= await response.json();
        // console.log(data)
  
        if(data.status==='okay'){
            // localStorage.setItem('token',data.user)
            Swal.fire(
                {title:'Your idea is submitted successfully',
                icon:'success',
                confirmButtonColor: '#5ae4a7'}
              )
          localStorage.clear();
            navigate(`/`);
        }
        else if(data.status==='error-val'){
            Swal.fire(
                {title:'You have already submitted your idea',
                text:'In case if you have any problem please contact administrator!',
                icon:'warning',
                confirmButtonColor: '#5ae4a7'}
              )
          localStorage.clear();
            navigate(`/`);  
  
        }
        else if(data.status==='error'){
          Swal.fire(
              {title:'Failed to submit idea!',
              text:'contact administrator!',
              icon:'error',
              confirmButtonColor: '#5ae4a7'}
            )
          localStorage.clear();
          navigate(`/`);
      }
    
  }

  
  async function handleOver(event){
      //http://localhost:5001
      idea_id=u_name+"_"+idea_id;
      const response= await fetch("https://api-cwipedia.herokuapp.com/api/user-idea",
      {
          method:'POST',
          headers:{
              'x-access-token':localStorage.getItem('token'),
              'email_id':localStorage.getItem('email'),
              'Content-Type': 'application/json'    
          },
          body: JSON.stringify({
              // _id,
              idea_id,
              ideaUrl,
              ideaOpUrl,
          }),
          
      })
      const data= await response.json();
      // console.log(data)

      if(data.status==='okay'){
          // localStorage.setItem('token',data.user)
          Swal.fire(
              {title:'Time is over',
              text:"Your idea is submitted successfully",
              icon:'success',
              confirmButtonColor: '#5ae4a7'}
            )
        localStorage.clear();
          navigate(`/`);
      }
      else if(data.status==='error-val'){
          Swal.fire(
              {title:'You have already submitted your idea',
              text:'In case if you have any problem please contact administrator!',
              icon:'warning',
              confirmButtonColor: '#5ae4a7'}
            )
        localStorage.clear();
          navigate(`/`);  

      }
      else if(data.status==='error'){
        Swal.fire(
            {title:'Failed to submit idea!',
            text:'contact administrator!',
            icon:'error',
            confirmButtonColor: '#5ae4a7'}
          )
        localStorage.clear();
        navigate(`/`);
    }
  
}

  let [deadline, setDeadline] = useState(localStorage.getItem('current_time'))
  // console.log(deadline)
  const myArray = deadline.split(" ");
  // console.log(myArray)
  const sepTime= myArray[2].split(":")
  // console.log(sepTime[0]-1)

  // myArray[0]+myArray[1]+sepTime[0]-1+sepTime[1]+sepTime[2]

  let m=myArray[0];
  let mm=m.concat(" ",myArray[1]," ",sepTime[0]-1,":",sepTime[1],":",sepTime[2] )

  // setTimeout(AlertFun, mm);

// console.log(isCompleted())


 const Completionist = () => {
  handleOver();
  localStorage.clear();
  navigate("/")
  
  function MyAll(){
    Swal.fire(
      {
      icon:'warning',
      title:'Your Time is Completed',
      confirmButtonColor: '#5ae4a7',
      allowOutsideClick: false,
      allowEscapeKey: false}
    ).then((result) => {
      if (result.isConfirmed) {
        window.location.reload(true);
      }
    })
  }

  setTimeout(MyAll,2000);


  return(
    <>
    <span>Your Time is already completed</span></>
  )
 }
    return(
      <>
        <div className="container">
          <div>
            <div className="col text-center">
            <p className="h3">Hey, {u_name}</p>
            <p className="h4">Selected Domain: {localStorage.getItem('user_domain')}</p>
              <p className="h5 ">Time Left</p>
                <div className="h5 ">
                <Countdown daysInHours={true}  date={deadline}>
                  <Completionist />
                </Countdown><br/>
                  {/*<a href={ideaFile} target="_blank" className="btn cbtn m-2" >View Ideas</a>*/}
                    <a href="https://server.cwipedia.in/DATA/National-Competition-2022.pdf"  target="_blank" className="btn cbtn m-2" >View Ideas</a>
                    <a href="https://ghrcem.raisoni.net/v-ideate-u-validate/" target="_blank" className="btn cbtn m-2" >View IDEA VALIDATION MATRIX</a>
                  <Logout/> 
                </div>
            </div>

            <form className="center loginform" onSubmit={handleIdea}>
            <div className="form-floating mb-3">
                <input type="name" className="form-control shadow" id="floatingInput1" placeholder="abc"
                value={idea_id}
                onChange={(e)=> setIdea_id(e.target.value)} required
                />
                <label htmlFor="floatingInput1">Enter idea id</label>
            </div>
                <div className="mt-3">
                <div className="input-group mt-3">
                    <label id="pay" className="form-label h5 ml-1">Upload validated idea: </label>
                    <div className="input-group ">
                    <input type="file" name="idea" className="form-control shadow" id="pay" 
                  onChange={(e) => setIdea(e.target.files[0])} required/>
                    <button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04" onClick={sendIdea}>{uploadText}</button>
                </div>
                </div>
                </div>
                  
                    <div className="mt-3">
                    <div className="input-group shadow mt-3">
                        <label id="pay" className="form-label h5 ml-1">Upload File (If needed) </label>
                        <div className="input-group ">
                        <input type="file" name="ideaOp" className="form-control" id="pay" onChange={(e) => setIdeaOp(e.target.files[0])} />
                        <button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04" onClick={sendIdeaOp}>{uploadText0}</button>
                    </div>
                    </div>
                    </div>

                <button type="submit" className="btn cbtn mt-2  shadow" >Submit</button>
            </form>
        </div>

        </div>
          <ToastContainer />
        </>
    )
}
export default LinkPage;