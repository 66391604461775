import { useState,useEffect } from "react";
import {  Link,useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

const Login= ()=>{

    let navigate = useNavigate();


    const [email, setEmail] = useState('');
    const [psw, setPsw] = useState('');



    useEffect(() => {

	}, [])  

    async function login(event){
        event.preventDefault()
        // const response= await fetch("http://localhost:5001/api/login",
       const response= await fetch("https://api-cwipedia.herokuapp.com/api/login",
        {
            method:'POST',
            headers:{
                'x-access-token':localStorage.getItem('token'),
                'Content-Type': 'application/json'    
            },
            body: JSON.stringify({
                email,
                psw,
            }),
        })
        const data= await response.json();
        // console.log(data)

        if(data.status==='okay'){
            localStorage.setItem('token',data.user)
            localStorage.setItem('email',email);
            // localStorage.setItem('user_null',data._id);

            Swal.fire(
                {title:'Login Successful!',
                text:'Please read all Instructions carefully!',
                icon:'success',
                confirmButtonColor: '#5ae4a7',
                allowOutsideClick: false,
                allowEscapeKey: false}
              ).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload(true);
                }
              })

              async function getUser(event){
                const response = await fetch(`https://api-cwipedia.herokuapp.com/api/get_user/${email}`, {
                    method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                    const data= await response.json();
                    // console.log(data);
                    localStorage.setItem('user_null',data._id);
                    localStorage.setItem('user_domain',data.domain)
                    localStorage.setItem('user_lead_name',data.name)
                    localStorage.setItem('user_team',data.teamName)
                    localStorage.setItem('user_name1',data.name1)
                    localStorage.setItem('user_name2',data.name2)
                    localStorage.setItem('user_name3',data.name3)

                }
                getUser()

              navigate(`/dashboard`);
              
            
        }
        else if(data.status==='error'){
            Swal.fire(
                {title:'Login Failed!',
                text:'Please check email or password!',
                icon:'error',
                confirmButtonColor: '#5ae4a7'}
              )
        }
        //to clear all field




        setEmail("");
        setPsw("")
        
    }

    return(
        <div className="login container page-bg text-center mt-4">
        <form className="center loginform" onSubmit={login}>
            <p className="h5">V Ideate U Validate</p>
        <p className="display-5">Login</p>
        <div className="form-floating mb-3">
            <input type="email" className="form-control" id="floatingInput" placeholder="abc@example.com"
            value={email}
            onChange={(e)=> setEmail(e.target.value)} />
            <label htmlFor="floatingInput">Email</label>
        </div>
        <div className="form-floating">
            <input type="password" className="form-control" id="floatingPassword" placeholder="Password" 
            value={psw}
            onChange={(e)=> setPsw(e.target.value)} />
            <label htmlFor="floatingPassword">Password</label>
        </div>
        
        <div className="form-label">
            <p>Don't have an account?
            <Link to="/register" className="white"> Register Now</Link></p>
            <Link to="/reset-password">Forgot Password?</Link>
        </div>
        <button type="submit" className="btn cbtn mt-2" >Login</button>
        </form>
        </div>
    )
}
export default Login;