import React from "react";
import jwt from "jsonwebtoken";
import {Navigate, Outlet} from 'react-router-dom'
	
	const useAuth=()=>{
        const lStorage = localStorage.getItem("token");
        try {
            jwt.verify(lStorage, "internalrgi");
            return true;
        } catch (error) {
            console.log("token not found");
	        return false
        }
	}
    

const  ProtectedRoutes=(props) =>{
	

	  const auth=useAuth()

	  return auth?<Outlet/>: <Navigate to="/"/>
	}
	

	export default ProtectedRoutes;;